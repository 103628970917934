<template>
    <v-container fluid>
        <v-card
            width="800"
            class="mx-auto pa-4"
            elevation="2"
            outlined
        >
            <v-card-title
                class=" py-4"
            >
                <h3 class="font-weight-bold">
                    Employees
                </h3>
                <v-spacer></v-spacer>
                <employee-new></employee-new>               
            </v-card-title>
            <v-tabs
                v-model="tab"
                color="primary"                
            >
                <v-tab
                    v-for="item in subMenus"
                    :key="item"
                >
                    {{ item }}
                </v-tab>
                <v-tabs-items v-model="tab">
                    <v-tab-item>                                
                        <employee-edit-timetable></employee-edit-timetable>
                    </v-tab-item>
                    <v-tab-item>
                        <employee-edit></employee-edit>                        
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-card>
        <v-snackbar
            v-model="snack"
            :timeout="3000"
            color="primary"
        >
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snack = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar> 
    </v-container>    
</template>

<script>
import EmployeeNew from './EmployeesNew';
import EmployeeEdit from './EmployeesEdit';
import EmployeeEditTimetable from './EmployeesEditTimetable'
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        EmployeeNew,
        EmployeeEdit,
        EmployeeEditTimetable,              
    },
    created(){
        this.initialize();
    },
    data: () => ({
        tab: 0,
        subMenus: [
            'Edit/View Timetable', 'Edit/Delete Teacher'
        ],
        snack: false,
        
    }),
    watch: {
        getSnack: {
            handler(val){
                console.log(val);
                if(val) this.snack = val;
            }
        },
        snack: {
            handler(val){
                console.log(val);
                if(!val) this.setSnack(val);
            }
        }
    },
    computed: {
        ...mapGetters({
            getSnack: 'employees/getSnack',
            snackText: 'employees/getSnackText',
        })
    },
    methods: {
        ...mapActions({
            getEmployees: 'employees/getEmployees',
            getEmployeeStatuses: 'employees/getEmployeeStatuses', 
        }),
        ...mapMutations({
            setEmployees: 'employees/setEmployees',
            setEmployeeStatuses: 'employees/setEmployeeStatuses',
            setSnack: 'employees/setSnack',
        }),
        async initialize(){
            try {
                const employeesPromise = this.getEmployees();
                const employeeStatusPromise = this.getEmployeeStatuses();

                let response = await employeesPromise;
                this.setEmployees(response.data);

                response = await employeeStatusPromise;
                //console.log(response);
                let records = response.data;
                let statuses = [];
                records.forEach(record => {
                    if(record.id > 1) statuses.push(record);
                });
                //console.log(statuses);
                this.setEmployeeStatuses(statuses);

            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }
        }
    }
}
</script>