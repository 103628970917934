<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="950"                
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <v-dialog
                        v-model="dialog"
                        max-width="700px"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="mr-4"
                            >
                                Add New Student
                            </v-btn>                            
                        </template>

                        <students-edit                            
                            v-bind:formTitle="formTitle"
                            v-bind:formClasses="formClasses"
                            v-on:close="closeEdit"
                            v-on:update-students="initialize"                            
                        ></students-edit>
                             
                    </v-dialog>

                    <students-upload
                       v-on:update-students="initialize"
                    ></students-upload>
                </v-card-title>
                
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"                        
                        item-key="student_id"                                            
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Students"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="deleteStudentConfirm"
                                            outlined
                                        >
                                            Delete
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>

                        </template>

                        <template  v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                tile
                                color="primary"
                                @click="editStudent(item)"
                                class="mr-6"
                                small
                            >
                                Edit/View
                                <v-icon
                                    small
                                    class="ml-3"
                                >
                                    mdi-pencil
                                </v-icon>
                                
                            </v-btn>
                            <v-btn
                                text
                                outlined
                                @click="deleteStudent(item)"
                                small
                            >    
                                Delete
                                <v-icon
                                    small                                    
                                >
                                    mdi-delete
                                </v-icon>
                                
                            </v-btn>    
                        </template>                        

                    </v-data-table>

                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        :color="snackColor"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsEdit from './StudentsEdit';
import StudentsUpload from './StudentsUpload.vue';

export default{ 
    components: {         
        StudentsEdit,
        StudentsUpload, 
    },

    created () {
        this.initialize();
    },

    data: () => ({
        headers: [
             {text: 'ID', align: 'start', sortable: 'false', value: 'id', width: '100'},
             {text: 'Name', align: 'start', sortable: 'false', value: 'name', },
             {text: 'Class', align: 'start', sortable: 'false', value: 'form_class_id', width: '100'},
             {text: 'Gender', align: 'start', sortable: 'false', value: 'gender', width: '80'},
             {text: '', align: 'start', sortable: false, value: 'actions', width: '300'},
        ],
        students: [],
        search: '',
        loading: false,
        snack: false,
        snackText: '',
        snackColor: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            birth_certificate_pin: '',
            date_of_birth: '',
            first_name: '',
            form_class_id: '',
            form_level: '',
            gender: '',
            last_name: '',
            name: '',
            student_id: '',            
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: '',
            id: '',    
        },
        defaultItem: {
            birth_certificate_pin: '',
            date_of_birth: '',
            first_name: '',
            form_class_id: '',
            form_level: '',
            gender: '',
            last_name: '',
            name: '',
            student_id: '', 
        },   
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        formClasses: [],
                       
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' :  
            this.editedItem.first_name + ' '
            + this.editedItem.last_name
        },
        studentDeleted () {
            return 'Deleting ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        }
    },

    watch: { 
        dialogDelete (val) {
            val || this.closeDelete()
        },
        
        dialog (val) {
            if(val && this.editedIndex == -1){
                this.setNewStudent()
            } 
        }
       
    },

    methods: {
        ...mapActions({
            getStudents: 'common/getCurrentStudents',
            getFormClasses: 'common/getFormClasses',
            getStudentStatus: 'students/getStudentStatus',
            delete: 'students/deleteStudent',            
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
        }),

        async initialize(){
            this.loading = true;
            this.students = [];
            // console.log('initializing');
            try {
                const promiseStudents = this.getStudents();
                const promiseFormClasses = this.getFormClasses();
                const promiseStudentStatus = this.getStudentStatus();

                const [ 
                    { data: students }, 
                    { data: formClasses },
                    { data: studentStatus }
                ] = 
                await Promise.all([
                    promiseStudents, 
                    promiseFormClasses,
                    promiseStudentStatus,
                ]);
                
                this.students = students.map(record => {
                    record.name = record.last_name + ' ' + record.first_name;
                    return record;
                });
                this.formClasses = formClasses;
                this.loading = false;
                this.statuses = studentStatus;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
        },

        editStudent(item){
            this.setEditedStudent(item);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        
        deleteStudent(item){
            //console.log(item);           
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
            //console.log(this.$refs);
            this.$nextTick(() => {
                console.log(this.$refs['student-status'])
                //this.$refs['student-status'].isActive = true;
            })
           
        },
        
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },

        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;
            setTimeout(() => {
                this.deleting = true;
            });
            
            console.log(this.deletedItem);
            this.setEditedStudent({
                student_id: this.deletedItem.id,
                student_status_id: this.status
            });
            try {
                let response = await this.delete();
                console.log(response);
                await this.initialize();                
                this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'                               
                this.snackColor = 'primary'
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackText = "Error Occured";
                this.snackColor = "red";
            }
            this.deleting = false;
            setTimeout(() =>{
                this.dialogDelete = false;                 
            })
            setTimeout(() => {
                this.snack = true;
                // this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'
            })           
            
        },

        closeEdit () {
            this.dialog = false;            
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });            
        },

        setNewStudent () {
            this.setEditedStudent(this.editedItem);
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-dialog{
        max-height: 100%
    }
</style>